import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import {Container, Navbar, Nav} from 'react-bootstrap'

const Header = () => (
  <header className="sticky-top">
      <Navbar expand='md' bg="black">
        <Container>
          <Navbar.Brand href='/' style={{maxWidth: '80%'}}>
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarResponsive' className="opsNavToggle p-0"/>
          <Navbar.Collapse id='navbarResponsive'>
              <Nav as='ul' className='ml-auto'>
                <Nav.Item as='li'>
                  <Link to='/About' className='nav-link' activeClassName='active'>About</Link>
                </Nav.Item>
                <Nav.Item as='li'>
                  <Link to='/Blog' className='nav-link' activeClassName='active'>Blog</Link>
                </Nav.Item>
                <Nav.Item as='li'>
                  <Link to='/Careers' className='nav-link' activeClassName='active'>Careers</Link>
                </Nav.Item>
                <Link to='/Contact' className='btn btn-primary ml-4' activeClassName='active'>CONTACT</Link>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
