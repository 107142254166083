import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import imgLogoName from '../images/OpsmorphLogoName.svg'

const Logo = () => {
  return <img
      src={imgLogoName}
      id="imgOpsmorphLogo"
      alt="Opsmorph Logo"
      style={{width: "270px", height: "35px"}}
    />
  // const data = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "OpsmorphLogoName.svg" }) {
  //       childImageSharp {
  //         fixed(width: 300) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(data)
  // if (!data?.placeholderImage?.childImageSharp?.fixed) {
  //   return <div>Picture not found</div>
  // }
  // return <Img fixed={data.placeholderImage.childImageSharp.fixed} />
}

export default Logo
