import React from "react"
import Obfuscate from 'react-obfuscate'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import 'bootstrap/dist/css/bootstrap.min.css'
import { AiFillTwitterCircle, AiFillLinkedin } from 'react-icons/ai'
import { Container, Row, Col, Nav} from 'react-bootstrap'
import CookieConsent from 'react-cookie-consent'
import imgLogo from '../images/OpsmorphLogo.svg'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cyberessentialsCertificationMarkColour.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "black" }}
        buttonStyle={{ backgroundColor: "darkcyan", fontSize: "13px", color: "white" }}
        declineButtonStyle={{ backgroundColor: "orangered", fontSize: "13px", color: "white" }}
      >
        <span style={{ fontSize: "13px"}}>This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
        We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
        If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</span>
      </CookieConsent>
      <footer> 
        <div className="footer-outer-div bg-dark">
          <Container>
            <Row className="p-4">
              <Col md={4} className="text-center" style={{padding: 10, display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Nav as='ul' className='flex-column'>
                  <Nav.Item as='li'>
                    <Link to='/About' className='nav-link p-1' activeClassName='active'>About</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Blog' className='nav-link p-1' activeClassName='active'>Blog</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Careers' className='nav-link p-1' activeClassName='active'>Careers</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Contact' className='nav-link p-1' activeClassName='active'>Contact</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/' className='nav-link p-1' activeClassName='active'>Home</Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={4} className="text-center" style={{padding: 10, display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img
                  src={imgLogo}
                  alt="opsmorph Logo"
                  style={{height: "60px", width: "60px"}}
                /> 
                <p style={{fontSize:15, marginTop: 7, marginBottom: 5}}>OPSMORPH LIMITED</p>
                <p style={{fontSize:15, marginBottom: 5}}>Company Number 12971585</p>
                <p style={{fontSize:15, marginBottom: 0}}>
                International House<br/>
                12 Constance Street<br/>
                London<br/>
                E16 2DQ</p>
              </Col>
              <Col md={4} className="text-center" style={{padding: 10, display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div>
                  <a 
                      href='https://twitter.com/opsmorph'
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Link to Opsmorph Twitter"
                  >
                      <AiFillTwitterCircle style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
                  </a>
                  <a 
                      href='https://www.linkedin.com/company/opsmorph/'
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Link to Opsmorph LinkedIn"
                  >
                      <AiFillLinkedin style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
                  </a>
                </div>
                <Obfuscate
                  email="info@opsmorph.com"
                  className="p-2 nav-link"
                />
                <Img
                    style={{height: "96px", width: "80px"}}
                    className="mt-4"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Cyber Essentials"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-black">
          <Container >
            <Row className="text-center">
              <Col md={4} className="p-2">
                <div className="">© {new Date().getFullYear()} Opsmorph Limited</div>
              </Col>
              <Col md={4} className="p-2">
                <Link to='/TermsConditions' className='nav-link p-0'>Terms and Conditions</Link>
              </Col>
              <Col md={4} className="p-2">
                <Link to='/PrivacyPolicy' className='nav-link p-0'>Privacy Policy</Link>
              </Col>
            </Row>
          </Container>
        </div>   
        {/* <div className="footer-outer-div bg-dark">
          <Container>
            <Row className="p-4">
              <Col md={4} className="text-center">
                <h5 className="mt-4">
                  Links
                </h5>
                <Nav as='ul' className='ml-auto flex-column'>
                  <Nav.Item as='li'>
                    <Link to='/About' className='nav-link p-2' activeClassName='active'>About</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Blog' className='nav-link p-2' activeClassName='active'>Blog</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Careers' className='nav-link p-2' activeClassName='active'>Careers</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/Contact' className='nav-link p-2' activeClassName='active'>Contact</Link>
                  </Nav.Item>
                  <Nav.Item as='li'>
                    <Link to='/' className='nav-link p-2' activeClassName='active'>Home</Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={4} className="text-center">
                <h5 className="mt-4">
                  Details
                </h5>
                <p>OPSMORPH LIMITED<br/>
                Company Number 12971585<br/>
                International House<br/>
                12 Constance Street<br/>
                London<br/>
                E16 2DQ</p>
                <img
                  src={imgLogo}
                  alt="opsmorph Logo"
                  style={{height: "80px", width: "80px"}}
                />        
              </Col>
              <Col md={4} style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h5 className="mt-4">
                  Contact
                </h5>
                <Obfuscate
                  email="info@opsmorph.com"
                  className="p-2 nav-link"
                />
                <h5 className="mt-4">
                  Connect
                </h5>
                <div>
                  <a 
                      href='https://twitter.com/opsmorph'
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Link to Opsmorph Twitter"
                  >
                      <AiFillTwitterCircle style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
                  </a>
                  <a 
                      href='https://www.linkedin.com/company/opsmorph/'
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Link to Opsmorph LinkedIn"
                  >
                      <AiFillLinkedin style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
                  </a>
                </div>
                <Img
                    style={{height: "96px", width: "80px"}}
                    className="mt-4"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Cyber Essentials"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-black">
          <Container >
            <Row className="text-center">
              <Col md={4} className="p-2">
                <div className="">© {new Date().getFullYear()} Opsmorph Limited</div>
              </Col>
              <Col md={4} className="p-2">
                <Link to='/TermsConditions' className='nav-link p-0'>Terms and Conditions</Link>
              </Col>
              <Col md={4} className="p-2">
                <Link to='/PrivacyPolicy' className='nav-link p-0'>Privacy Policy</Link>
              </Col>
            </Row>
          </Container>
        </div>    */}
      </footer>
    </>
  )
}

export default Footer

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "cyberessentialsCertificationMarkColour.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
